<template>
  <div :class="type" class="search-hints">
    <input
      id="ssearch"
      ref="ssearch"
      v-model="searchQuery"
      :class="{ active: searchQuery }"
      @input="trottled"
      @focus="focused = true"
      @blur="focused = false"
      type="search"
      name="ssearch"
      autocomplete="off"
      :placeholder="type === 'db' ? 'Какое аниме ищем?' : 'Что будем смотреть?'"
    >
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <span>Ничего не найдено</span>
      </div>
      <ul v-else>
        <nuxt-link
          v-for="(item, i) in searchHints"
          :to="`${
            type === 'db'
              ? `/catalog/${postUrl(item.id, item.title)}`
              : `/smotrim?id=${item.id}`
          }`"
          :key="`sh-${i}`"
          tag="li"
        >
          <div class="poster">
            <img :src="item.poster" alt="">
          </div>
          <p>
            <strong>{{ item.title }}</strong>
            <span v-if="item.year">{{ item.year }}</span>
            <span v-if="item.genres"> {{ item.genres.slice(0, 3).join(', ') }}</span>
          </p>
        </nuxt-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: { type: { type: String, require: true, default: 'db' } },
  watch: {
    route() {
      this.searchQuery = ''
      this.searchHints = []
    }
  },
  data() {
    return {
      searchQuery: '',
      searchHints: []
    }
  },
  methods: {
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    getSearchHints() {
      const body = {
        value: this.searchQuery
      }
      this.$socket.emit(`animeast:sh`, body, (cb) => {
        if (cb.error) {
          this.$store.commit('setError', { status: 'warn', msg: cb.error.message })
          setTimeout(() => this.$store.commit('clearError'), 2000)
        } else {
          this.searchHints = cb
        }
      })
    }
  }
}
</script>
